@import '../../../common/css/colors.css';
@import '../../../common/css/media.css';

:root {
    --animationTiming: .5s;
}

/* stylelint-disable time-no-imperceptible */
/* 0s is required to make the animation work correctly */
.navigation {
    overflow: hidden; /* required for menu expand animation on mobile */
    max-height: 0;
    transition:
        visibility 0s linear var(--animationTiming),
        max-height var(--animationTiming) ease-in-out;
    visibility: hidden;
}

@media (--largeDevice) {
    .navigation {
        max-height: initial;
        overflow: visible;
        transition: none;
        visibility: visible;
    }
}

.showMenu {
    max-height: 280px;
    transition-delay: 0s;
    visibility: visible;
}

.menu {
    clear: both;
}

@media (--largeDevice) {
    .menu {
        float: right;
        width: 75%;
        clear: none;
        margin-top: 2.2em;
    }
}

.menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu li {
    float: left;
    width: 100%;
    border-top: 1px solid var(--navLinkTopBorder);
}

@media (--largeDevice) {
    .menu li {
        width: 16%;
        border-top: none;
    }
}

.menuLink {
    composes: link from '../../../common/css/typography.css';
    color: var(--navLink);
    text-align: center;
    width: 100%;
    margin: 0;
    padding: .7em 1em;
    float: left;
    line-height: 2em;
}

@media (--largeDevice) {
    .menuLink {
        padding: .3em 0;
        font-size: .95em;
    }
}
