@import '../../common/css/media.css';

.pageWrapper {
    composes: pageWrapper from '../../common/css/layout.css';
    margin-bottom: 3.5em;
    padding-bottom: 0;
}

.featuresContainer {
    margin-top: 20px;
}

.featuresContainerPurchase {
    margin-top: 50px;
}

@media (--smallDevice) {
    .featuresContainerPurchase {
        margin-top: 55px;
    }
}

.shadow {
    composes: shadow from '../../common/css/global.css';
    margin-top: 40px;
}

@media only screen and (min-width: 979px) {
    .shadow {
        margin-top: 90px;
    }
}

