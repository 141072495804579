@import './colors.css';
@import './media.css';

/* stylelint-disable selector-no-type */
/* global styles */
/* stylelint-disable selector-no-universal */
/* box-sizing */

@font-face {
    font-family: 'Fira Sans';
    font-weight: 400;
    font-style: normal;
    src: url('../../assets/fonts/FiraSans/FiraSans-Regular.eot');
    src:
        url('../../assets/fonts/FiraSans/FiraSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/FiraSans/FiraSans-Regular.woff') format('woff'),
        url('../../assets/fonts/FiraSans/FiraSans-Regular.woff2') format('woff2'),
        url('../../assets/fonts/FiraSans/FiraSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 500;
    src: url('../../assets/fonts/FiraSans/FiraSans-SemiBold.eot');
    src:
        url('../../assets/fonts/FiraSans/FiraSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/FiraSans/FiraSans-SemiBold.woff') format('woff'),
        url('../../assets/fonts/FiraSans/FiraSans-SemiBold.woff2') format('woff2'),
        url('../../assets/fonts/FiraSans/FiraSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 600;
    src: url('../../assets/fonts/FiraSans/FiraSans-SemiBold.eot');
    src:
        url('../../assets/fonts/FiraSans/FiraSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/FiraSans/FiraSans-Bold.woff') format('woff'),
        url('../../assets/fonts/FiraSans/FiraSans-Bold.woff2') format('woff2'),
        url('../../assets/fonts/FiraSans/FiraSans-Bold.ttf') format('truetype');
}

* {
    box-sizing: border-box;
}

html {
    line-height: 1;
}

body {
    margin: 0;
    padding: 0;
    font-size: 100%;
    font-family: 'Fira Sans', 'arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--darkText);
}

.buttonReset {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    font-family: inherit;
    overflow: visible;
    padding: 0;
}

.buttonReset::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.clearfix::after {
    content: '';
    display: table;
    clear: both;
}

.shadow {
    height: 10px;
    background: url('../../assets/images/shadow.png') top center no-repeat;
    background-size: 100% 10px;
    border-top: 1px solid var(--navBottomBorder);
}

/* stylelint-disable  declaration-no-important  */
/* shared screen reader styles across other projects */
.srOnly {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}
/* stylelint-enable */

/* stylelint-disable */
:global(#truste-consent-track) {
    border-top: 0.1rem solid #fff;
    background-color: #615693 !important;
}
/* stylelint-enable */
