@import '../../common/css/colors.css';

.root {
    composes: buttonReset from '../../common/css/global.css';
    width: 50px;
    height: 36px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
}

.root span {
    display: block;
    position: absolute;
    height: 7px;
    width: 100%;
    background: var(--navLink);
    border-radius: 7px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.root span:nth-child(1) {
    top: 0;
}

.root span:nth-child(2),
.root span:nth-child(3) {
    top: 14px;
}

.root span:nth-child(4) {
    top: 28px;
}

.root.open span:nth-child(1) {
    top: 14px;
    width: 0%;
    left: 50%;
}

.root.open span:nth-child(2) {
    transform: rotate(45deg);
}

.root.open span:nth-child(3) {
    transform: rotate(-45deg);
}

.root.open span:nth-child(4) {
    top: 14px;
    width: 0%;
    left: 50%;
}
