.pageWrapper {
    composes: pageWrapper from '../../common/css/layout.css';
}

.heading {
    composes: heading from '../../common/css/typography.css';
}

.bodyText {
    composes: bodyText from '../../common/css/typography.css';
}

.introText {
    composes: introText from '../../common/css/typography.css';
}

.bullets {
    composes: bullets from '../../common/css/typography.css';
}
