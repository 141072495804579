.pageWrapper {
    composes: pageWrapper from '../../../../common/css/layout.css';
}

.promoLayoutContainer {
    composes: promoLayoutContainer from '../../../../common/css/layout.css';
}

.promoLayoutAside {
    composes: promoLayoutAside from '../../../../common/css/layout.css';
}

.promoLayoutContent {
    composes: promoLayoutContent from '../../../../common/css/layout.css';
}

.heading {
    composes: heading from '../../../../common/css/typography.css';
}

.subheading {
    composes: subheading from '../../../../common/css/typography.css';
}

.bodyText {
    composes: bodyText from '../../../../common/css/typography.css';
}

.introText {
    composes: subheading from '../../../../common/css/typography.css';
    margin-top: 0;
}

.bullets {
    composes: bullets from '../../../../common/css/typography.css';
}
