@import '../../common/css/colors.css';

.pageWrapper {
    composes: pageWrapper from '../../common/css/layout.css';
}

.heading {
    composes: heading from '../../common/css/typography.css';
}

.bodyText {
    composes: bodyText from '../../common/css/typography.css';
}

.bullets {
    composes: bullets from '../../common/css/typography.css';
}

.socket {
    float: right;
    padding-right: 8rem;
    display: none;
}

@media screen and (min-width: 979px) {
    .socket {
        display: block;
    }
}

.cord {
    margin: 4em auto 2em;
    display: block;
    max-width: 100%;
}

@media screen and (min-width: 979px) {
    .cord {
        margin: 6em auto 3em;
    }
}
