@import '../../common/css/colors.css';

:root {
    --listOffset: .75em;
}

.heading {
    color: var(--primaryBrand);
    font-size: 1.75rem;
    font-weight: 500;
    text-align: center;
}

@media screen and (min-width: 680px) {
    .heading {
        text-align: left;
    }
}

.subheading {
    color: var(--primaryBrand);
    font-weight: 500;
    font-size: 1.125rem;
}

.bodyText {
    color: var(--fadedText);
    line-height: 1.4em;
}

.introText {
    composes: bodyText;
    margin-top: 0;
}

.list {
    padding: 0 .65em;
}

.list li {
    padding: .35em 0 .3em var(--listOffset);
}

.list a {
    color: var(--primaryBrand);
}

.list a:hover {
    color: var(--secondaryBrand);
}

.bullets {
    composes: list;
    list-style: none;
}

.bullets li {
    background: url('../../assets/images/arrow.png') no-repeat top 10px left;
}

.numberedList {
    composes: list;
    list-style: none;
    counter-reset: numberedListCounter;
}

.numberedList li {
    position: relative;
    margin-left: var(--listOffset);
}

.numberedList li::before {
    content: counter(numberedListCounter) ')';
    counter-increment: numberedListCounter;
    position: absolute;
    top: 8px;
    left: calc(-1 * var(--listOffset));
}

.link {
    color: var(--linkText);
    cursor: pointer;
    text-decoration: none;
}

.link:hover {
    color: var(--secondaryBrand);
    text-decoration: underline;
}

.bold {
    font-weight: 500;
}
