@import '../../../../common/css/colors.css';

.pageWrapper {
    composes: pageWrapper from '../../../../common/css/layout.css';
}

.promoLayoutContainer {
    composes: promoLayoutContainer from '../../../../common/css/layout.css';
}

.promoLayoutAside {
    composes: promoLayoutAside from '../../../../common/css/layout.css';
}

.promoLayoutContent {
    composes: promoLayoutContent from '../../../../common/css/layout.css';
}

.heading {
    composes: heading from '../../../../common/css/typography.css';
}

.subheading {
    composes: subheading from '../../../../common/css/typography.css';
}

.title {
    composes: subheading from '../../../../common/css/typography.css';
    color: var(--fadedText);
}

.bodyText {
    composes: bodyText from '../../../../common/css/typography.css';
    margin: 0 0 15px;
}
