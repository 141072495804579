@import '../../common/css/colors.css';

.pageWrapper {
    composes: pageWrapper from '../../common/css/layout.css';
}

.heading {
    composes: heading from '../../common/css/typography.css';
}

.subheading {
    composes: subheading from '../../common/css/typography.css';
    color: var(--fadedText);
    margin: 0 0 5px;
}

.bodyText {
    composes: bodyText from '../../common/css/typography.css';
}

.introText {
    composes: introText from '../../common/css/typography.css';
}

.bullets {
    composes: bullets from '../../common/css/typography.css';
}

.link {
    composes: link from '../../common/css/typography.css';
}
