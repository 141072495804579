@import '../../common/css/colors.css';

.text {
    font-size: 2.2em;
    font-weight: 600;
    margin: 0;
    text-align: center;
}

.subText {
    color: var(--fadedText);
    display: block;
    font-size: 13px;
    padding-top: 5px;
    text-align: center;
}
