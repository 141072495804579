.root {
    composes: link from '../../common/css/typography.css';
    text-align: left;
}

.externalWrapper {
    display: inline-block;
    margin-left: .25rem;
}

.externalIcon {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url('../../assets/images/popup-square-icon.svg') no-repeat;
}

.screenReader {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
