@import '../../../common/css/colors.css';
@import '../../../common/css/media.css';

.subheader {
    display: block;
    margin: 0;
    color: var(--darkText);
    font-size: 1.25em;
    font-weight: 300;
    text-transform: capitalize;
}

@media (--smallDevice) {
    .subheader {
        display: inline-block;
    }
}

@media (--mediumDevice) {
    .subheader {
        font-size: 1.35em;
    }
}

.headerDivider {
    composes: shadow from '../../../common/css/global.css';
    margin-top: 25px;
}

@media (--smallDevice) {
    .headerDivider {
        margin-top: 12px;
    }
}

.featureBlocks {
    margin-top: 1.4em;
}

@media (--mediumDevice) {
    .featureBlocks {
        margin-top: 32px;
    }
}

@media (--largeDevice) {
    .featureBlock {
        background: bottom right no-repeat;
        padding-bottom: 48px;
    }

    .featureBlock + .featureBlock {
        border-top: 1px solid var(--navBottomBorder);
        padding-top: 64px;
    }

    .featureBlockReverse {
        composes: featureBlock;
        background-position: bottom left;
    }

    .featureBlockAccountMobile {
        composes: featureBlock;
        background-image: url('../../../assets/images/accountmobile.png');
    }

    .featureBlockAccountMobile399 {
        composes: featureBlock;
        background-image: url('../../../assets/images/accountmobile399.png');
    }

    .featureBlockCardFinger {
        composes: featureBlockReverse;
        background-image: url('../../../assets/images/cardfinger.png');
        min-height: 255px;
    }

    .featureBlockCardFinger .features {
        margin-left: 42%;
    }

    .featureBlockCardPadlock {
        composes: featureBlock;
        background-image: url('../../../assets/images/cardpadlock.png');
    }
}

.features {
    list-style: none;
    margin: 0;
    padding: 0;
}

@media (--largeDevice) {
    .features {
        margin-right: 2%;
        width: 58%;
    }
}

.feature {
    background: url('../../../assets/images/tick.svg') top 5px left/16px 16px no-repeat;
    line-height: 1.5em;
    margin-bottom: 1em;
    padding-left: 2em;
}

.featureHeading {
    color: var(--fadedText);
    line-height: 24px;
    margin: 0;
}

.featureDescription {
    line-height: 1.4em;
    margin: 0;
}

.applyRow {
    margin-top: 25px;
}

@media (--mediumDevice) {
    .applyRow {
        margin-top: 40px;
    }
}

@media (--largeDevice) {
    .applyRow {
        margin-top: 0;
    }
}

.interestRateContainer {
    display: block;
}

@media (--mediumDevice) {
    .interestRateContainer {
        display: inline-block;
        margin-right: 40px;
        vertical-align: middle;
    }
}

.applyButton {
    display: block;
    margin-top: 15px;
    vertical-align: middle;
    width: auto;
}

@media (--mediumDevice) {
    .applyButton {
        display: inline-block;
        margin-top: 0;
        max-width: 300px;
        min-width: 260px;
    }
}
