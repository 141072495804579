@import '../../common/css/media.css';

.pageWrapper {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 0 1em;
}

@media (--xsmallDevice) {
    .pageWrapper {
        padding-top: .5em;
    }
}

@media (--smallDevice) {
    .pageWrapper {
        padding: .5em 0 2em;
    }
}

.pageWrapper::after {
    content: ' ';
    display: block;
    clear: both;
}

@media (min-width: 1100px) {
    .pageWrapper {
        max-width: 1100px;
        width: 95%;
    }
}

/* stylelint-disable no-unsupported-browser-features */
/* flexbox */
.promoLayoutContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
/* stylelint-enable no-unsupported-browser-features */

.promoLayoutAside {
    margin-top: 1.25em;
    width: 220px;
}

.promoLayoutContent {
    flex: 1 1 350px;
}

@media (--smallDevice) {
    .promoLayoutContent {
        margin-right: 1em;
    }
}
