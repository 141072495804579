@import '../../../../common/css/colors.css';
@import '../../../../common/css/media.css';

.pageWrapper {
    composes: pageWrapper from '../../../../common/css/layout.css';
    margin-bottom: 3.5em;
    padding-bottom: 0;
}

.heading {
    composes: heading from '../../../../common/css/typography.css';
}

.subheading {
    composes: subheading from '../../../../common/css/typography.css';
    color: var(--fadedText);
}

.bodyText {
    composes: bodyText from '../../../../common/css/typography.css';
}

.introText {
    composes: introText from '../../../../common/css/typography.css';
}