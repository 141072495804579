@import '../../common/css/media.css';
@import '../../common/css/colors.css';

.root {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding-bottom: 5px;
}

@media screen and (min-width: 640px) {
    .root {
        padding-bottom: 15px;
    }
}

@media screen and (min-width: 979px) {
    .root {
        margin-bottom: 25px;
        padding-bottom: 60px;
        background: url('../../assets/images/hero_background.jpg') bottom center no-repeat;
        min-height: 425px;
    }
}

.bold {
    composes: bold from '../../common/css/typography.css';
}

.heading,
.subheading {
    text-align: center;
    padding: 0;
    margin: 0;
}

.heading {
    composes: bold from '../../common/css/typography.css';
    font-size: 1.4em;
}

@media (--mediumDevice) {
    .heading {
        font-size: 2em;
        margin: 1rem 0 0;
    }
}

@media screen and (min-width: 979px) {
    .heading {
        font-size: 2.4em;
    }
}

.subheading {
    font-size: 1.2em;
    font-weight: 300;
    margin: .8rem 0;
}

@media (--mediumDevice) {
    .subheading {
        font-size: 1.45em;
        margin: 1rem 0 2rem;
    }
}

@media screen and (min-width: 979px) {
    .subheading {
        font-size: 1.8em;
    }
}

.card.mobile {
    float: left;
    width: 100%;
    padding-bottom: 8px;
}

@media (--xsmallDevice) {
    .card.mobile {
        padding-bottom: 18px;
    }
}

.card.mobile img {
    height: auto;
    width: 45%;
    float: left;
    margin-left: 33px;
}

@media screen and (min-width: 641px) {
    .card.mobile img {
        margin-left: 22%;
    }
}

.card.desktop {
    display: none;
    float: left;
    width: 41%;
}

.card.desktop img {
    height: auto;
    width: 380px;
    display: block;
}

@media screen and (min-width: 979px) {
    .card.desktop {
        display: block;
    }

    .card.mobile {
        display: none;
    }
}

.cardLink {
    display: block;
    margin: 0 auto;
    width: 380px;
}

.apr {
    composes: bold from '../../common/css/typography.css';
    float: right;
    width: 25%;
    padding-top: 100px;
    padding-right: 2%;
    font-size: 30px;
    line-height: 30px;
    color: #333;
    text-align: left;
    text-decoration: none;
}

@media screen and (max-width: 979px) {
    .apr {
        width: 28%;
        min-width: 130px;
        padding-right: 0;
        padding-top: 30px;
        font-size: 34px;
        line-height: 100%;
    }
}

@media screen and (max-width: 640px) {
    .apr {
        width: 44%;
        min-width: 130px;
        padding-right: 0;
        padding-top: 30px;
        font-size: 34px;
        line-height: 34px;
    }
}

@media screen and (max-width: 580px) {
    .apr {
        font-size: 25px;
        line-height: 24px;
    }
}

@media screen and (max-width: 340px) {
    .apr {
        font-size: 23px;
        line-height: 22px;
    }
}

.apr .smallPrint {
    float: left;
    font-size: 14px;
    font-weight: 300;
    line-height: 15px;
    color: #666;
    padding-top: 2px;
}

@media screen and (max-width: 580px) {
    .apr .smallPrint {
        font-size: 12px;
    }
}

@media screen and (max-width: 340px) {
    .apr .smallPrint {
        font-size: 10px;
        line-height: 11px;
        padding-top: 0;
    }
}

.features {
    float: right;
    width: 100%;
}

@media screen and (min-width: 979px) {
    .features {
        width: 56%;
    }
}

.features ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
}

/* 530 = 510 + 20 to account for scrollbars in Firefox & Edge */
@media screen and (min-width: 530px) {
    .features ul {
        width: 510px;
    }
}

@media screen and (min-width: 979px) {
    .features ul {
        width: initial;
        margin: 5px 0 15px;
    }
}

.feature {
    margin: 0;
    padding: 3px 20px 3px 30px;
    font-size: 1.1em;
    line-height: 1.1em;
    font-weight: 400;
    background: url('../../assets/images/chevron-purple.svg') center left/ 20px 20px no-repeat;
}

@media (--smallDevice) {
    .feature {
        padding-top: 6px;
        padding-bottom: 6px;
        line-height: 29px;
    }
}

@media screen and (min-width: 979px) {
    .feature {
        padding-top: 9px;
        padding-bottom: 9px;
        font-size: 1.3em;
    }
}

.applyButtonWrapper {
    margin: 5px 0 10px;
}

.applyButton {
    width: 100%;
    font-size: 23px;
}

@media (--mediumDevice) {
    .applyButtonWrapper {
        width: 47%;
        margin-left: 20%;
    }
}

@media screen and (min-width: 979px) {
    .applyButtonWrapper {
        width: auto;
        margin: 0;
    }

    .applyButton {
        width: auto;
        font-size: 32px;
    }
}

.aprExample {
    border-bottom: 1px solid var(--navBottomBorder);
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    padding-bottom: 15px;
}

@media screen and (min-width: 340px) {
    .aprExample {
        font-size: 19px;
        line-height: 24px;
    }
}

@media screen and (min-width: 525px) {
    .aprExample {
        font-size: 20px;
        line-height: 25px;
    }
}

@media (--largeDevice) {
    .aprExample {
        font-size: 22px;
        font-weight: 400;
        line-height: 28px;
    }
}

.substantiation {
    font-size: 13px;
    line-height: 1.4;
    font-weight: 400;
    color: #666;
}

@media screen and (min-width: 480px) {
    .substantiation {
        font-size: 15px;
    }
}

@media screen and (min-width: 525px) {
    .substantiation {
        font-size: 18px;
    }
}
