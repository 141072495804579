@import '../../../common/css/colors.css';

.skipContentLink {
    composes: link from '../../../common/css/typography.css';
    width: 1px;
    height: 1px;
    position: absolute;
    top: -10px;
    overflow: hidden;
    transition: height .2s;
}

.skipContentLink:focus {
    width: 50%;
    top: 57px;
    font-size: 1.3rem;
    height: 50px;
    text-align: center;
    left: 0;
    z-index: 1001;
    display: block;
    background-color: var(--skipLinkBackground);
    padding: 14px;
    box-shadow: 0 0 2px 1px var(--skipLinkBorder);
}

@media screen and (min-width: 979px) {
    .skipContentLink:focus {
        top: 100px;
        font-size: 1.4rem;
    }
}
