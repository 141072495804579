@import '../../common/css/media.css';

.wrap {
    margin-bottom: 0;
}

@media (--xsmallDevice) {
    .wrap {
        margin-bottom: 10px;
    }
}

.topbar {
    composes: clearfix from '../../common/css/global.css';
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

@media (min-width: 1100px) {
    .topbar {
        max-width: 1100px;
        width: 95%;
    }
}

.burgerBarButton {
    display: block;
    margin: .65em 0;
    float: right;
}

@media (--largeDevice) {
    .burgerBarButton {
        display: none;
    }
}

.logoContainer {
    /* stylelint-disable no-unsupported-browser-features */
    /* These properties are not succeptable to any IE11 known issues */
    align-items: center;
    display: flex;
    /* stylelint-enable no-unsupported-browser-features */
    float: left;
    padding: 1em 1em 1em 0;
    text-align: left;
}

.logo {
    display: block;
    height: 100%;
    width: 100px;
}

@media (--largeDevice) {
    .logoContainer {
        height: 100px;
        float: left;
    }

    .logo {
        width: 165px;
    }
}

.apply {
    display: none;
}

@media (--largeDevice) {
    .apply {
        display: block;
        position: absolute;
        right: 0;
        top: 37px;
    }
}

.shadow {
    composes: shadow from '../../common/css/global.css';
}
