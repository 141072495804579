@import '../../common/css/colors.css';

.root {
    background: linear-gradient(to bottom, var(--footerBackgroundStop1) 0%, var(--footerBackgroundStop2) 100%); /* stylelint-disable-line max-line-length */ /* long variable names */
    padding: 2em 0;
}

@media (min-width: 979px) {
    .root {
        padding: 4em 0;
    }
}

.wrap {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

@media (min-width: 1100px) {
    .wrap {
        max-width: 1100px;
        width: 95%;
    }
}

.logoContainer {
    /* stylelint-disable no-unsupported-browser-features */
    /* These properties are not succeptable to any IE11 known issues */
    align-items: flex-start;
    display: flex;
    /* stylelint-enable no-unsupported-browser-features */
    height: 50px;
}

.logo {
    display: block;
    width: 90px;
    height: 100%;
}

@media (min-width: 480px) {
    .logoContainer {
        height: 75px;
    }

    .logo {
        width: 120px;
    }
}

@media (min-width: 979px) {
    .logoContainer {
        height: 100px;
    }

    .logo {
        width: 150px;
    }
}

.affiliate {
    width: 100%;
    overflow: hidden;
}

.affiliate img {
    max-width: 180px;
    height: auto;
    float: right;
    margin-top: 3em;
}

@media (min-width: 480px) {
    .affiliate img {
        max-width: 230px;
    }
}

@media (min-width: 979px) {
    .affiliate img {
        max-width: 275px;
    }
}

.nav {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
}

.nav ul {
    list-style: none;
    float: left;
    display: block;
    width: 50%;
    margin-bottom: 20px;
    padding: 0;
}

@media (min-width: 979px) {
    .nav {
        width: 32.203%;
        float: left;
        margin-right: 1.695%;
    }

    .nav ul {
        float: left;
        display: block;
        width: 50%;
    }
}

.footerLink {
    text-decoration: none;
    font-size: .8em;
    color: var(--lightText);
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 7px;
}

.footerLink:hover {
    transition: color 500ms ease-out;
    color: var(--footerLinkHover);
}

.smallprint {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
}

.smallprint p {
    display: inline;
    margin-bottom: .5em;
    color: var(--lightText);
    font-weight: 400;
    line-height: 1.3em;
    font-size: .8em;
}

@media (min-width: 979px) {
    .smallprint {
        width: 66.102%;
        float: right;
        margin-right: 0;
    }
}
