@import '../../common/css/colors.css';

.imageContainer {
    position: relative;
    margin-bottom: 15px;
    background-color: var(--promoAsideBackground);
    border: 1px solid var(--promoAsideBorder);
    padding: 6px;
    border-radius: 4px;
}

.image {
    width: 100%;
    border: 1px solid var(--promoAsideBorder);
    border-radius: 4px;
}

.linkWrapper {
    bottom: 25px;
    left: 17px;
    position: absolute;
    width: 184px;
}

.aprContainer {
    border: 1px solid var(--promoAsideBorder);
    background: var(--promoAsideBackground);
    font-size: 32px;
    border-radius: 4px;
    padding: 12px;
    width: 220px;
    margin-bottom: 15px;
    text-align: center;
    color: var(--promoAsideFooterText);
}

.aprSmall {
    font-size: 12px;
    display: block;
}

.applyLink {
    font-size: 1.2em;
    display: block;
}
