@import '../../common/css/colors.css';

.root {
    font-size: .8rem;
}

.active {
    color: var(--breadcrumbActive);
}

.link {
    color: var(--linkText);
    cursor: pointer;
    text-decoration: none;
}

.link::after {
    content: '';
    display: inline-block;
    background: url('../../assets/images/double-chevron.svg') center left 3px / 7px 8px no-repeat;
    padding: 0 3px;
    width: 7px;
    height: 8px;
}

.link:hover {
    color: var(--breadcrumbHover);
    text-decoration: underline;
}
