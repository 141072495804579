.pageWrapper {
    composes: pageWrapper from '../../common/css/layout.css';
}

.promoLayoutContainer {
    composes: promoLayoutContainer from '../../common/css/layout.css';
}

.promoLayoutAside {
    composes: promoLayoutAside from '../../common/css/layout.css';
}

.promoLayoutContent {
    composes: promoLayoutContent from '../../common/css/layout.css';
}

.heading {
    composes: heading from '../../common/css/typography.css';
}

.subheading {
    composes: subheading from '../../common/css/typography.css';
}

.bodyText {
    composes: bodyText from '../../common/css/typography.css';
}

.introText {
    composes: introText from '../../common/css/typography.css';
}

.bullets {
    composes: bullets from '../../common/css/typography.css';
}

.parentList {
    composes: list from '../../common/css/typography.css';
    list-style: none;
}
