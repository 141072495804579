@import '../../common/css/colors.css';
@import '../../common/css/media.css';

.root {
    margin-top: 1.25em;
}

@media (--largeDevice) {
    .root {
        padding-left: 1.25em;
        border-left: 8px solid var(--sectionSidebar);
    }
}
