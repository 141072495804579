@import '../../common/css/colors.css';

.root {
    position: relative;
    background: var(--actionLinkBackground);
    box-shadow: 0 0 0 transparent, 0 1px 1px rgba(255, 255, 255, .3);
    text-align: center;
    text-decoration: none;
    display: inline-block;
}

.root::before {
    content: ' ';
    position: absolute;
}

.root:hover {
    background: var(--actionLinkBackgroundHover);
}

.small {
    font-size: 1.1em;
    padding: .4em 2.5em .6em 1.2em;
    border-radius: 3px;
}

.small::before {
    width: 20px;
    height: 15px;
    top: .5em;
    right: .6em;
    background: url('../../assets/images/chevron-white.svg') center right/ 20px 15px no-repeat;
}

.medium {
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .1), 0 1px 1px rgba(255, 255, 255, .3);
    font-size: 1.4em;
    padding: .6em 3em .7em 1.5em;
}

.medium::before {
    width: 20px;
    height: 20px;
    background: url('../../assets/images/chevron-white.svg') center right/ 20px 20px no-repeat;
    right: 32px;
    /* stylelint-disable no-unsupported-browser-features */
    /* partial support refers to multiple / divide not being supported (Android 4.4) which isn't
    used here */
    top: calc(50% - 10px);
    /* stylelint-enable no-unsupported-browser-features */
}

.large {
    font-size: 32px;
    font-weight: 500;
    padding: .5em 3.6em .7em 1.7em;
    border-radius: 4px;
}

.large::before {
    width: 24px;
    height: 24px;
    top: .65em;
    right: 1.5em;
    background: url('../../assets/images/chevron-white.svg') center right/ 24px 24px no-repeat;
}

.pill {
    font-size: 1.4em;
    padding: .5em 1.25em .5em 0;
    border-radius: 30px;
    width: 100%;
}

.pill::before {
    width: 16px;
    height: 16px;
    top: .6em;
    right: .8em;
    background: url('../../assets/images/chevron-white.svg') center right/ 16px 16px no-repeat;
}

.linkContent {
    color: var(--lightText);
}
