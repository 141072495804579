:root {
    --lightText: #fff;
    --fadedText: #666;
    --darkText: #000;
    --linkText: #204fb0;

    --primaryBrand: #4e4991;
    --secondaryBrand: #168a24;

    --actionLinkBackground: #128020;
    --actionLinkBackgroundHover: #0c5315;

    --navLink: #615693;
    --navBottomBorder: #ccc;
    --navLinkTopBorder: #e3e3e3;

    --breadcrumbActive: #797676;
    --breadcrumbHover: var(--secondaryBrand);

    --skipLinkBackground: #fff;
    --skipLinkBorder: var(--primaryBrand);

    --sectionSidebar: #dde3ed;

    --promoAsideBackground: #fcfcfc;
    --promoAsideBorder: #dedede;
    --promoAsideFooterText: var(--primaryBrand);

    --guidanceBorder: #dfdfdf;

    --videoGradientStart: #f1f1f1;
    --videoGradientEnd: #fff;

    --footerBackgroundStop1: #070517;
    --footerBackgroundStop2: #302a7a;
    --footerLinkHover: #12b721;
}
