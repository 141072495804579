@import '../../common/css/media.css';

.pageWrapper {
    composes: pageWrapper from '../../common/css/layout.css';
}

.heading {
    composes: heading from '../../common/css/typography.css';
}

.subheading {
    composes: subheading from '../../common/css/typography.css';
}

.bodyText {
    composes: bodyText from '../../common/css/typography.css';
}

.introText {
    composes: introText from '../../common/css/typography.css';
}

.bullets {
    composes: bullets from '../../common/css/typography.css';
}

.col {
    float: left;
    width: 100%;
}

@media screen and (min-width: 680px) {
    .col {
        width: 50%;
    }
}

.parentList {
    composes: list from '../../common/css/typography.css';
    list-style: none;
}
