@import '../../../common/css/colors.css';
@import '../../../common/css/media.css';

.heading {
    color: var(--navLink);
    font-size: 1.2em;
    font-weight: 300;
    line-height: 1.1em;
    margin-bottom: 0;
    margin-top: 32px;
}

@media (--smallDevice) {
    .heading {
        font-size: 1.2em;
    }
}

@media (--largeDevice) {
    .heading {
        font-size: 1.8em;
    }
}

.blurb {
    composes: bodyText from '../../../common/css/typography.css';
    font-size: 1em;
    margin-bottom: 0;
    margin-top: 16px;
}

@media (--smallDevice) {
    .blurb {
        font-size: 1em;
    }
}

.perks {
    list-style: none;
    margin-bottom: 0;
    margin-top: 25px;
    padding: 0;
    vertical-align: top;
}

.perks + .perks {
    margin-top: 0;
}

@media (--largeDevice) {
    .perks {
        display: inline-block;
        margin: 2.8em 0 1.5em;
        width: 50%;
    }

    .perks + .perks {
        margin: 2.8em 0 1.5em;
    }
}

.perk {
    composes: bodyText from '../../../common/css/typography.css';
    background: top left/22px 100% no-repeat;
    font-size: .9em;
    font-weight: 600;
    margin-bottom: 14px;
    padding-left: 43px;
    position: relative;
}

@media (--smallDevice) {
    .perk {
        font-size: 1em;
        margin-bottom: 25px;
        padding-left: 54px;
    }
}

@media (--largeDevice) {
    .perk {
        margin-bottom: 32px;
    }
}

.perkEnvelope {
    composes: perk;
    background-image: url('../../../assets/images/envelope.svg');
}

.perkFile {
    composes: perk;
    background-image: url('../../../assets/images/file.svg');
}

.perkGlobe {
    composes: perk;
    background-image: url('../../../assets/images/globe.svg');
}

.perkCog {
    composes: perk;
    background-image: url('../../../assets/images/cog.svg');
}

.perkLock {
    composes: perk;
    background-image: url('../../../assets/images/lock.svg');
}

.perkPage {
    composes: perk;
    background-image: url('../../../assets/images/page.svg');
}

.perkFwd {
    composes: perk;
    background-image: url('../../../assets/images/fwd.svg');
}

.iosApp,
.androidApp {
    display: inline-block;
    margin-right: 5px;
    vertical-align: top;
}

.iosAppLogo,
.androidAppLogo {
    display: block;
}

.interestRateContainer,
.applyButton {
    margin-top: 20px;
}

.applyButton {
    width: 100%;
}

@media (--mediumDevice) {
    .interestRateContainer,
    .applyButton {
        display: inline-block;
    }

    .applyButton {
        float: right;
        min-width: 260px;
        max-width: 300px;
        width: auto;
    }
}

@media (--largeDevice) {
    .apps,
    .interestRateContainer,
    .applyButton {
        display: inline-block;
        margin-top: 0;
        vertical-align: top;
        width: 33.333%;
    }
}
