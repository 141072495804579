@import '../../common/css/colors.css';

.pageWrapper {
    composes: pageWrapper from '../../common/css/layout.css';
}

.heading {
    composes: heading from '../../common/css/typography.css';
}

.subheading {
    composes: subheading from '../../common/css/typography.css';
}

.srOnly {
    composes: srOnly from '../../common/css/global.css';
}

.introText {
    composes: bodyText from '../../common/css/typography.css';
}

.bodyText {
    composes: bodyText from '../../common/css/typography.css';
    float: left;
    margin-top: 0;
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .bodyText {
        width: 58%;
    }
}

@media only screen and (min-width: 970px) {
    .bodyText {
        width: 67%;
    }
}

.optionWrapper {
    float: left;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid var(--guidanceBorder);
}

.image {
    display: none;
    float: left;
    margin-right: 25px;
    margin-top: 30px;
}

@media only screen and (min-width: 768px) {
    .image {
        display: block;
    }
}

.learnMore {
    float: left;
    text-transform: uppercase;
    width: 200px;
}

@media only screen and (min-width: 768px) {
    .learnMore {
        float: right;
    }
}
