.pageWrapper {
    composes: pageWrapper from '../../../common/css/layout.css';
}

.heading {
    composes: heading from '../../../common/css/typography.css';
}

.subheading {
    composes: subheading from '../../../common/css/typography.css';
}

.bodyText,
.listText {
    composes: bodyText from '../../../common/css/typography.css';
    margin-top: 0;
}

.bullets {
    composes: bullets from '../../../common/css/typography.css';
}
